import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import axios from 'axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24 mt-10`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-secondary-500 text-gray-100 rounded-xl relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-solid border-t-0 border-r-0 border-l-0 border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const Header = tw.h2`text-xl! md:text-xl! lg:text-3xl! text-yellow-400`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-yellow-400 text-secondary-500 rounded-xl rounded-tl-none rounded-br-none font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

//eslint-disable-next-line
export default () => {
  const [email, setEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (window.location.pathname.indexOf('?') > -1) {
      window.location.pathname = '/kontakt';
    }
  }, [window.location.pathname]); //eslint-disable-line

  function clearForm() {
    setMessage('');
    setEmail('');
    setCustomerName('');
  }

  function sendMail(e) {
    e.preventDefault();
    axios
      .get(`/submit.php?email=${email}&name=${customerName}&message=${message}`)
      .then(
        toast.success('Erfolgreich abgesendet!', {
          position: 'top-center',
          autoClose: 4000
        })
      )
      .then(clearForm())
      .catch((error) => {
        toast.error(
          'Ups! Es gab einen Fehler beim senden der Nachricht: \n' + error,
          { position: 'top-center', autoClose: 4000 }
        );
      });
  }

  return (
    <Container>
      <Content>
        <h3 tw="text-3xl font-bold text-left">KREATIVBÖDEN - BESCHICHTUNGEN</h3>
        <Container tw="mb-10">
          <p tw="text-left text-xl">
            Michael Riegler <br></br>
            Fichtengasse 219 <br></br>
            A-2640 Enzenreith<br></br>
            Telefon:{' '}
            <a tw="text-primary-400" href="tel:+436644039079">
              0664 / 403-90-79
            </a>{' '}
            <br></br>
            E-Mail:{' '}
            <a tw="text-primary-400" href="mailto:kreativboden@aon.at">
              kreativboden@aon.at
            </a>
          </p>
        </Container>
        <FormContainer>
          <div tw="mx-auto max-w-4xl relative">
            <Header>KONTAKTFORMULAR</Header>
            <form action="#" onSubmit={(e) => sendMail(e)}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Ihr Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      placeholder="z.B. Max Mustermann"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Ihre Email Adresse</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="z.B. max@mail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Ihre Nachricht</Label>
                    <TextArea
                      id="message-input"
                      name="message"
                      value={message}
                      placeholder="z.B. Ich hätte Interesse an einem Steinteppich."
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit">SENDEN</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};
