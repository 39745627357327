import React from 'react';
import tw from 'twin.macro';
import { createPortal } from 'react-dom';
import Lottie from 'lottie-react';
import LoadingAnimation from 'lottie/loader.json';

import { motion } from 'framer-motion';

const Loader = tw.div`w-full h-full flex flex-col items-center justify-center absolute z-40 top-0 left-0 bg-gray-100`;

const Heading = tw.h2`text-4xl font-black uppercase text-primary-400`;

const Sized = tw.div`w-48 h-48`;
//eslint-disable-next-line
export default () => {
  return (
    <>
      {createPortal(
        <motion.main
          className="main__container"
          initial={{ opacity: 0 }}
          animate={{ opacity: '100%' }}
          exit={{ x: '100%', opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Loader>
            <Sized>
              <Lottie animationData={LoadingAnimation} loop={true}></Lottie>
            </Sized>
            <Heading>Kreativboden</Heading>
          </Loader>
        </motion.main>,
        document.body
      )}
    </>
  );
};
