import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';
import BackgroundImage from '../../images/cta-bg.jpg';

import styled from 'styled-components';
import { ContentXl } from 'components/misc/Layouts';
import { Link } from 'react-router-dom';

const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  background-image: url(${BackgroundImage});
`;

const PrimaryBackgroundContainer = tw.div`py-16 -mx-8 lg:py-20 bg-transparent relative`;
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(
  ColumnContainer
)`text-2xl sm:text-4xl font-bold max-w-sm  md:max-w-2xl`;
const Subheading = tw.h3`text-primary-500`;

const Paragraph = tw.p`text-gray-100 font-light my-8 lg:my-5 text-sm mx-8 md:mx-0 xl:text-xl leading-loose`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const LinkStyle = tw.span`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-xl rounded-tr-none rounded-bl-none font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(LinkStyle)`shadow text-secondary-400 bg-yellow-400`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-500 opacity-5`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-5`;

//eslint-disable-next-line
export default ({
  subheading = 'PRODUKT UND LEISTUNGSÜBERSICHT',
  heading = 'Schauen Sie sich doch mal unsere Produkte und Leistungen im Detail an.',
  primaryLinkText = 'PRODUKTE',
  primaryLinkUrl = '/produkte',
  pushDownFooter = false
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Paragraph>{heading}</Paragraph>
            </TextContainer>
            <LinksContainer>
              <Link to={primaryLinkUrl}>
                <PrimaryLink>{primaryLinkText}</PrimaryLink>
              </Link>
            </LinksContainer>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentXl>
    </Container>
  );
};
