import React from 'react';
import tw from 'twin.macro';
import {
  Container as ContainerBase,
  ContentWithPaddingXl
} from 'components/misc/Layouts';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';

const Container = tw(
  ContainerBase
)`relative my-8 lg:my-10 bg-secondary-400 text-gray-100 -mx-8 px-8`;

const StatsContainer = tw.div`flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-xl justify-center lg:justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black text-[#27B1FF]`;
const StatValue2 = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black text-[#FFF627]`;
const StatValue3 = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black text-[#FF275B]`;
const StatValue4 = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black text-gray-100`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-1/2 left-0 w-80 h-80 transform -translate-x-40 -translate-y-48 text-primary-500 opacity-5`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-1/2 right-1/2 w-80 h-80 transform -translate-x-20 -translate-y-64 text-primary-500 opacity-5`;
const DecoratorBlob3 = tw(
  SvgDecoratorBlob1
)`absolute top-1/2 right-1/2 w-40 h-40 hidden md:block transform -translate-x-20 -translate-y-64 text-primary-500 opacity-5`;
const DecoratorBlob4 = tw(
  SvgDecoratorBlob1
)`absolute top-[20px] right-0 w-96 h-96 transform -translate-x-20 -translate-y-64 text-primary-500 opacity-5`;

//eslint-disable-next-line
export default ({
  stats = [
    {
      key: 'Abgewickelte projekte',
      value: '800+',
      color: 'text-[#27B1FF]'
    },
    {
      key: 'Dienstleistungen',
      value: 'Vielfältige',
      color: 'text-[#FFF627]'
    },
    {
      key: 'Jahre Erfahrung',
      value: '20+',
      color: 'text-[#FF275B]'
    },
    {
      key: 'Kunden',
      value: 'Zufriedene',
      color: 'text-[#FFFFFF]'
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              {index === 0 && <StatValue>{stat.value}</StatValue>}
              {index === 1 && <StatValue2>{stat.value}</StatValue2>}
              {index === 2 && <StatValue3>{stat.value}</StatValue3>}
              {index === 3 && <StatValue4>{stat.value}</StatValue4>}
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
        <DecoratorBlobContainer>
          <DecoratorBlob1 />
          <DecoratorBlob2 />
          <DecoratorBlob3></DecoratorBlob3>
          <DecoratorBlob4></DecoratorBlob4>
        </DecoratorBlobContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
