import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import { Container } from 'components/misc/Layouts.js';
import Lottie from 'lottie-react';
import LoadingAnimation from 'lottie/spin.json';

import Project1 from 'images/projectDekor2.jpg';
import Project2 from 'images/slider2.jpg';
import Project3 from 'images/slider3.jpeg';
import Project4 from 'images/projectDekor.jpg';
import Project5 from 'images/project5.jpg';
import Project6 from 'images/6.jpg';

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto mb-40`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) => css`
    background-image: url('${props.imagesrc}');
  `}
  ${tw`w-64 h-64 bg-cover bg-center rounded-xl rounded-tl-none rounded-br-none`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6 text-center`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

//eslint-disable-next-line
export default ({
  cards = [
    {
      imageSrc: Project1,
      product: 'DEKORBELAG',
      name: 'Dekorbelag im Innenbereich'
    },
    {
      imageSrc: Project2,
      product: 'STEINTEPPICH',
      name: 'Steinteppich im Außenbereich'
    },
    {
      imageSrc: Project3,
      product: 'STEINTEPPICH',
      name: 'Steinteppich auf der Terasse'
    },
    {
      imageSrc: Project4,
      product: 'DEKORBELÄGE',
      name: 'Dekorbelag im Innenbereich'
    },
    {
      imageSrc: Project5,
      product: 'STEINTEPPICH',
      name: 'Steinteppich für Stiegen'
    },
    {
      imageSrc: Project6,
      product: 'Beschichtung',
      name: 'Beschichtung im Sanitärbereich'
    }
  ]
}) => {
  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 40));

      // Toggle loading state
      setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  return (
    <Container>
      <Cards>
        {loading ? (
          <Lottie tw="mt-40" animationData={LoadingAnimation} loop></Lottie>
        ) : (
          cards.map((card, index) => (
            <Card key={index}>
              <CardImage imagesrc={card.imageSrc} />
              <CardContent>
                <span className="product">{card.product}</span>
                <span className="name">{card.name}</span>
              </CardContent>
            </Card>
          ))
        )}
      </Cards>
    </Container>
  );
};
