import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import BackgroundImage from '../../images/productHeader.jpg';

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144 bg-product`}
  background-image: url(${BackgroundImage});
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-secondary-400 leading-snug`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

//eslint-disable-next-line
export default ({
  heading = (
    <>
      PRODUKT UND <br></br> LEISTUNGSÜBERSICHT
    </>
  )
}) => {
  return (
    <Container>
      <HeroContainer>
        <Content>
          <Heading>{heading}</Heading>
        </Content>
      </HeroContainer>
    </Container>
  );
};
