import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter';
import { SectionHeading } from 'components/misc/Headings';
import ScrollToTop from 'helpers/ScrollToTop';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

//eslint-disable-next-line
export default ({ headingText = 'Impressum' }) => {
  return (
    <>
      <Header />
      <AnimationRevealPage>
        <ScrollToTop></ScrollToTop>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>Zuletzt aktualisiert am: August 14, 2023</p>

              <h2>
                Informationen und Offenlegung gemäß §5 (1) ECG, § 25 MedienG, §
                63 GewO und § 14 UGB
              </h2>

              <h2>Webseitenbetreiber</h2>
              <p>Michael Riegler</p>

              <h2>Anschrift</h2>
              <p>Fichtengasse 219, 2640 Gloggnitz</p>

              <h2>Gewerbeaufsichtbehörde</h2>
              <p>Bezirkshauptmannschaft Neunkirchen</p>

              <h2>Kontaktdaten</h2>
              <p>
                Telefon:{' '}
                <a tw="text-primary-400" href="tel:+436644039079">
                  0664 / 403-90-79
                </a>{' '}
                <br></br>
                E-Mail:{' '}
                <a tw="text-primary-400" href="mailto:kreativboden@aon.at">
                  kreativboden@aon.at
                </a>
              </p>

              <h2>
                Anwendbare Rechtsvorschrift:{' '}
                <a href="www.ris.bka.gv.at">www.ris.bka.gv.at</a>
              </h2>
              <h2>Berufsbezeichnung: Bodenleger</h2>

              <h3>Online Streitbeilegung</h3>
              <p>
                Online Streitbeilegung: Verbraucher, welche in Österreich oder
                in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind,
                haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf
                von Waren oder Dienstleistungen im Rahmen einer
                Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische
                Kommission stellt eine Plattform hierfür bereit:{' '}
              </p>
              <a href="https://ec.europa.eu/consumers/odr">
                https://ec.europa.eu/consumers/odr
              </a>

              <h3>Urheberrecht</h3>
              <p>
                Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich
                möglich ist, diversen Schutzrechten (z.B dem Urheberrecht).
                Jegliche Verwendung/Verbreitung von bereitgestelltem Material,
                welche urheberrechtlich untersagt ist, bedarf schriftlicher
                Zustimmung des Webseitenbetreibers.
              </p>

              <h3>Haftungsausschluss</h3>
              <p>
                Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
                Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte
                externer Links. Für den Inhalt der verlinkten Seiten sind
                ausschließlich deren Betreiber verantwortlich. Sollten Sie
                dennoch auf ausgehende Links aufmerksam werden, welche auf eine
                Webseite mit rechtswidriger Tätigkeit/Information verweisen,
                ersuchen wir um dementsprechenden Hinweis, um diese nach § 17
                Abs. 2 ECG umgehend zu entfernen. Die Urheberrechte Dritter
                werden vom Betreiber dieser Webseite mit größter Sorgfalt
                beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den
                betroffenen Inhalt umgehend entfernen.
              </p>
            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
