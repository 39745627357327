import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { SectionHeading } from 'components/misc/Headings.js';

import ShieldIconImage from '../../images/shield-icon.svg';
import CustomizeIconImage from '../../images/customize-icon.svg';
import FastIconImage from '../../images/fast-icon.svg';
import ReliableIconImage from '../../images/reliable-icon.svg';
import SimpleIconImage from '../../images/simple-icon.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`block md:flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-2 lg:px-6 py-10 border-2 border-dashed border-red-500 rounded-xl rounded-br-none rounded-tl-none mt-12`}
  .imageContainer {
    ${tw`border-solid border-2 border-secondary-400 text-center rounded-xl rounded-tr-none rounded-bl-none p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-base md:text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-gray-600 text-xs md:text-sm leading-loose`}
  }
`;

const Paragraph = tw.p`my-5 lg:my-8 text-center text-sm lg:text-base font-medium text-gray-600 lg:mx-0`;
const NumbersContainer = tw.div`w-24 h-24 flex items-center justify-center border-2 border-secondary-400`;
const Numbers = tw.span`text-4xl font-light text-secondary-400`;

//eslint-disable-next-line
export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: 'Versiegelungen',
      description:
        'Schützt den Boden vor Verschleiß, Feuchtigkeit und Schmutz, einfache Reinigung.'
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'Dekorbeläge',
      description: 'Vielfältig, individuelle Optik, langlebig und pflegeleicht.'
    },
    {
      imageSrc: ReliableIconImage,
      title: 'Steinteppiche',
      description:
        'Rutschfest, langlebig und pflegeleicht, große Auswahl an Farben und Materialien.'
    },
    {
      imageSrc: FastIconImage,
      title: 'Industrieböden',
      description:
        'Robust und widerstandsfähig, chemikalienbeständig, lange Lebensdauer.'
    },
    {
      imageSrc: SimpleIconImage,
      title: 'Bodenbeschichtungen',
      description:
        'Schützt den Boden vor Feuchtigkeit, erhöht die Rutschfestigkeit, langlebig und pflegeleicht.'
    },
    {
      imageSrc: SimpleIconImage,
      title: 'Raumgestaltungen',
      description:
        'Unsere Malereidienste verwandeln Räume in wahre Kunstwerke und schaffen eine angenehme Atmosphäre.'
    }
  ];

  const subheading =
    'Unser Ziel ist es, Ihren persönlichen Wünschen und Vorstellungen gerecht zu werden und ein Unikat zu schaffen, an dem Sie sich Tag für Tag erfreuen können. Wenn Sie auf der Suche nach hochwertigen Steinteppichen, Kunstharzböden oder Beschichtungen sind, sind Sie bei uns genau richtig.';

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>UNSERE DIENSTLEISTUNGEN IM ÜBERBLICK</Heading>

        {subheading && <Paragraph>{subheading}</Paragraph>}
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <NumbersContainer className="imageContainer">
                <Numbers>{i + 1}</Numbers>
              </NumbersContainer>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description text-md! mx-2">
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
