import React from 'react';
import styled from 'styled-components'; //eslint-disable-line
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-9.svg';
import {
  ContentWithPaddingXl,
  FullwidthContainer
} from 'components/misc/Layouts';

import { Link } from 'react-router-dom';

const SecondaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-secondary-500 rounded-2xl relative`;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-xl`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const LinkStyle = tw.span`relative w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-xl font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  LinkStyle
)`bg-yellow-400 rounded-tl-none! rounded-br-none! text-secondary-100 shadow-lg`;

const SecondaryLink = tw(
  LinkStyle
)`border border-solid text-gray-100 border-2 rounded-tr-none! rounded-bl-none! border-gray-500`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-xl`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-700 opacity-50`;

//eslint-disable-next-line
export default ({
  text = (
    <>
      NOCH FRAGEN?<br></br> NIMM KONTAKT AUF!
    </>
  ),
  primaryLinkText = 'KONTAKT',
  primaryLinkUrl = '/kontakt',
  secondaryLinkText = 'PRODUKTE',
  secondaryLinkUrl = '/produkte',
  pushDownFooter = false
}) => {
  return (
    <FullwidthContainer css={pushDownFooter && tw`mb-20 lg:mb-24 w-full!`}>
      <ContentWithPaddingXl>
        <SecondaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Text>{text}</Text>
            </TextContainer>
            <LinksContainer>
              <PrimaryLink>
                <Link to={primaryLinkUrl}>{primaryLinkText}</Link>
              </PrimaryLink>

              <SecondaryLink>
                <Link to={secondaryLinkUrl}>{secondaryLinkText}</Link>
              </SecondaryLink>
            </LinksContainer>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </SecondaryBackgroundContainer>
      </ContentWithPaddingXl>
    </FullwidthContainer>
  );
};
