import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase
} from '../misc/Headings.js';
import { PrimaryButton } from '../misc/Buttons.js';
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-4.svg';
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-5.svg';

import Slider1 from 'images/slider1.jpeg';
import Slider2 from 'images/slider2.jpg';
import Slider3 from 'images/slider3.jpeg';

import Project1 from 'images/projectDekor2.jpg';
import Project2 from 'images/projectDekor.jpg';
import Project3 from 'images/project5.jpg';
import Project4 from 'images/6.jpg';

import 'slick-carousel/slick/slick.css';

const Container = tw.div`relative max-w-full`;
const Content = tw.div`mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;

const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div((props) => [
  `background-image: url(${props.imagesrc});`,
  tw`rounded-xl rounded-tl-none bg-cover bg-center h-80 sm:h-96 lg:h-144`
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-lg`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 border-0 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5! border-0 h-5!`}
  }
`;

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;
const Description = tw.p`max-w-full mx-10 lg:max-w-md text-center lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

//eslint-disable-next-line
export default ({
  subheading = '',
  heading = 'INSPIRATION FÜR IHREN BODEN',
  description = (
    <>
      Entdecken Sie Bodenbeläge für jeden Raum und Stil. <br></br> <br></br>
      Kreativboden bietet eine breite Palette von innovativen Bodenbelägen und
      professionellen Dienstleistungen, die Ihnen helfen, den perfekten Boden
      für jeden Raum und Stil zu finden.
      <br></br>
      <br></br>
      Egal, ob Sie nach einem robusten Bodenbelag für stark frequentierte
      Bereiche suchen oder nach einem eleganten Design für Ihr Zuhause,
      Kreativboden hat die Lösung für Sie. Unsere Experten stehen Ihnen bei der
      Auswahl des richtigen Bodens zur Seite und bieten Ihnen eine
      professionelle Raumgestaltung für ein makelloses Endergebnis.
    </>
  ),
  testimonials = null
}) => {
  const defaultTestimonials = [
    {
      imageSrc: Slider1
    },
    {
      imageSrc: Slider2
    },
    {
      imageSrc: Slider3
    },
    {
      imageSrc: Project1
    },
    {
      imageSrc: Project2
    },
    {
      imageSrc: Project3
    },
    {
      imageSrc: Project4
    }
  ];

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials;

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo
          tw="text-center lg:hidden"
          subheading={subheading}
          heading={heading}
          description={description}
        />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider
                arrows={false}
                ref={setImageSliderRef}
                fade={true}
              >
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imagesrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer>
                <HeadingInfo
                  tw="hidden lg:block"
                  subheading={subheading}
                  heading={heading}
                  description={description}
                />
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
