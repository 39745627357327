import React from 'react';
import tw from 'twin.macro';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import logo from '../../images/logo.svg';

import { Link } from 'react-router-dom';

const Container = tw(ContainerBase)`bg-secondary-400 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-64 h-24 rounded-xl rounded-tl-none rounded-br-none bg-white p-4`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const LinkStyle = tw.span`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

//eslint-disable-next-line
export default () => {
  const year = new Date();
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <Link to="/">
              <LogoImg alt="KREATIVBODEN" src={logo} />
            </Link>
          </LogoContainer>
          <LinksContainer>
            <LinkStyle>
              <Link to="/">Home</Link>
            </LinkStyle>
            <LinkStyle>
              <Link to="/produkte">Produkte</Link>
            </LinkStyle>
            <LinkStyle>
              <Link to="/projekte">Projekte</Link>
            </LinkStyle>
            <LinkStyle>
              <Link to="/kontakt">Kontakt</Link>
            </LinkStyle>
            <LinkStyle>
              <Link to="/impressum">Impressum</Link>
            </LinkStyle>
          </LinksContainer>
          <CopyrightText>
            &copy; Copyright {year.getFullYear()}, KREATIVBODEN. All Rights
            Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
