import React, { useState, useEffect } from 'react';
import 'style.css';
import 'tailwindcss/lib/css/preflight.css';

import Header from 'components/headers/light.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from 'components/hero/TwoColumnWithVideo.js';
import Stats from 'components/features/ThreeColCenteredStatsPrimaryBackground.js';
import Features from 'components/features/DashedBorderSixFeatures.js';
import CTA from 'components/cta/GetStartedLight.js';
import ContactCTA from 'components/cta/GetStarted.js';
import Footer from 'components/footers/MiniCenteredFooter.js';

import Loader from 'components/Loader';

import HeroAlt from 'components/hero/BackgroundAsImageWithCenteredContent.js';

import { SectionDescription } from 'components/misc/Typography';

import { AnimatePresence, motion } from 'framer-motion';
import ScrollToTop from 'helpers/ScrollToTop.js';
import tw from 'twin.macro';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TwoColumnWithImageAndProfilePictureReview from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview';
import ProfileThreeColGrid from 'components/cards/ProfileThreeColGrid';
import SimpleContactUs from 'components/forms/SimpleContactUs';
import PrivacyPolicy from 'components/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';

const Container = tw.div`relative flex flex-col md:items-center max-w-screen-xl mx-auto`;

const SectionHeading = tw.h2`text-3xl sm:text-5xl font-black tracking-wide text-center mt-24`;
const SubSectionHeading = tw.h3`text-3xl sm:text-4xl font-bold text-left`;

const ProductContainer = tw.div`mt-4 py-10`;
const QuoteContainer = tw.div`border-solid border-l-4 border-r-0 border-t-0 border-b-0 border-primary-400 ml-4 pl-6 pr-4 pt-2 pb-8`;
const RedQuoteContainer = tw.div`border-solid border-l-4 border-r-0 border-t-0 border-b-0 border-red-400 ml-4 pl-6 pr-4 pt-2 pb-8`;

function App() {
  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 2000));

      // Toggle loading state
      setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <Router>
        <AnimatePresence>
          <Routes>
            <Route path="/produkte" element={<Products />} />
            <Route path="/projekte" element={<Projects />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </AnimatePresence>
      </Router>
    );
  }
}

function Home() {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 inline-block rounded-xl rounded-tr-none rounded-bl-none`;
  const description = (
    <>
      Wir bieten maßgeschneiderte Lösungen für eine Vielzahl von Anwendungen -
      von funktionalen Böden in Garagen und Werkstätten bis hin zu individuellen
      Gestaltungen von Terrassen, Treppen und Wohnräumen.
      <br></br> <br></br>
      Auch die Kombination verschiedener Bodenbeläge, wie etwa Steinteppich und
      Beschichtungen, stellen für uns kein Problem dar.
    </>
  );
  const imageCss = tw`rounded-2xl`;

  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: '100%' }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <AnimationRevealPage>
        <Hero
          heading={
            <>
              WILLKOMMEN BEI <HighlightedText>KREATIVBODEN</HighlightedText>
            </>
          }
          description={description}
          imageSrc="https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="KONTAKT"
        />
        <Stats></Stats>
        <Features></Features>
        <CTA></CTA>
        <Footer></Footer>
      </AnimationRevealPage>
    </motion.main>
  );
}

function Products() {
  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: '100%' }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <AnimationRevealPage>
        <HeroAlt></HeroAlt>
        <Container>
          <SectionHeading>UNSERE PRODUKTE & LEISTUNGEN</SectionHeading>
          <SectionDescription>
            Entdecken Sie die vielfältigen Möglichkeiten von Kreativboden.
            Innovative Bodenbeläge und professionelle Dienstleistungen
          </SectionDescription>
        </Container>
        <Container>
          <ProductContainer>
            <SubSectionHeading className="">KUNSTHARZBÖDEN</SubSectionHeading>
            <QuoteContainer>
              <SectionDescription>
                sind beinahe für jeden Einsatzzweck geeignet. Sie zeichnen sich
                durch lange Lebensdauer, höchste Belastbarkeit und einfache
                Reinigung aus. Mögliche Einsatzgebiete sind Hallen, Werkstätten
                Garagen, Verkaufslokale, Gaststätten, Großküchen, Büroraume aber
                auch Wohn- und Nassräume.
                <br></br>
                <br></br>
                Durch die große Farbtonvielfalt und unzählige
                Gestaltungsmöglichkeiten wie z.B. Farbchipseinstreuung,
                Lasurtechniken, usw., sowie die Möglichkeit verschiedener
                Oberflächen (seidenmatt, glänzend, rutschfest, rau, glatt,..)
                können wir den Boden nach Ihren persönlichen Vorstellungen
                herstellen. Kunstharzböden sind fest mit dem Untergrund
                verbunden und werden generell fugenlos verlegt. Dadurch sind sie
                leicht zu reinigen und können auch ohne großen Aufwand von
                Gebrauchsspuren befreit, bzw. umgestaltet werden.
              </SectionDescription>
            </QuoteContainer>
          </ProductContainer>
        </Container>

        <Container>
          <ProductContainer>
            <SubSectionHeading className="">STEINTEPPICHE</SubSectionHeading>
            <RedQuoteContainer>
              <SectionDescription>
                sind eine elegante Möglichkeit, einen Raum zu verschönern und zu
                gestalten. Mit Kreativboden haben Sie einen erfahrenen Partner
                an Ihrer Seite, der Sie bei der Gestaltung Ihres Steinteppichs
                unterstützt.
                <br></br>
                <br></br>
                Unsere Steinteppiche sind nicht nur äußerst langlebig, sondern
                auch wetter- und frostfest. Durch ihre natürlichen Farben
                strahlen sie ein behagliches Wohnklima aus und sind
                rutschhemmend. Darüber hinaus sind sie sehr leicht zu reinigen,
                da sie durch ihre porige Oberfläche kaum verschmutzungsanfällig
                sind. Steinteppiche sind damit optimal für Allergiker und
                Ausstellungshallen geeignet. Einsatzgebiete für Steinteppiche
                sind Terrassen, Balkone, Treppen, Gartenwege, Wohnräume,
                Geschäftslokale und Ausstellungsräume.
              </SectionDescription>
            </RedQuoteContainer>
          </ProductContainer>
        </Container>
        <ContactCTA></ContactCTA>
        <Container>
          <TwoColumnWithImageAndProfilePictureReview></TwoColumnWithImageAndProfilePictureReview>
        </Container>
        <CTA
          subheading={'KREATIVBODEN - UNSERE ARBEIT'}
          heading={'Schauen Sie sich unsere bisherigen Projekte genauer an!'}
          primaryLinkText="PROJEKTE"
          primaryLinkUrl="/projekte"
        ></CTA>
        <Footer></Footer>
      </AnimationRevealPage>
    </motion.main>
  );
}

function Projects() {
  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: '100%' }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <AnimationRevealPage>
        <HeroAlt heading={'PROJEKTE'}></HeroAlt>
        <Container>
          <SectionHeading>UNSERE PROJEKTE - EIN KURZÜBERBLICK</SectionHeading>
          <SectionDescription>
            Entdecken Sie die vielfältigen Möglichkeiten von Kreativboden -
            Innovative Bodenbeläge und professionelle Dienstleistungen:
            <br></br>
            Ein kleiner Ausschnitt unserer Projekte.
          </SectionDescription>
        </Container>
        <ProfileThreeColGrid></ProfileThreeColGrid>
        <CTA></CTA>
        <Footer></Footer>
      </AnimationRevealPage>
    </motion.main>
  );
}

function Contact() {
  return (
    <motion.main
      className="main__container"
      initial={{ opacity: 0 }}
      animate={{ opacity: '100%' }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ToastContainer></ToastContainer>
      <ScrollToTop></ScrollToTop>
      <Header></Header>
      <AnimationRevealPage>
        <HeroAlt heading={'KONTAKT'}></HeroAlt>
        <Container>
          <SectionHeading>KONTAKTAUFNAHME</SectionHeading>
          <SectionDescription>
            Bei Fragen, Anfragen oder für weitere Informationen können Sie uns
            gerne kontaktieren. Unser freundliches und professionelles Team
            steht Ihnen zur Verfügung, um Ihnen weiterzuhelfen.
            <br></br>
            <br></br>
            Wir freuen uns darauf, Ihre Anliegen zu beantworten und Ihnen bei
            Ihren Bodenbelagsbedürfnissen behilflich zu sein!
          </SectionDescription>
        </Container>
        <SimpleContactUs />
        <CTA></CTA>
        <Footer></Footer>
      </AnimationRevealPage>
    </motion.main>
  );
}

function Imprint() {
  return <PrivacyPolicy></PrivacyPolicy>;
}

export default App;
